<template>
	<page>
		<module title="安全台账详情">
			<flow-node :node="nodeList" :current="currentNode" :reject="rejectNode"></flow-node>
			<c-detail>
				<c-detail-item label="所属项目名称" full>
					{{detailInfo.project_name}}
				</c-detail-item>
				<c-detail-item label="企业(施工)单位">
					{{detailInfo.zbqy_name}}
				</c-detail-item>
				<c-detail-item label="业主单位">
					{{detailInfo.yzdw_name}}
				</c-detail-item>
				<c-detail-item label="备注说明">
					{{detailInfo.remark}}
				</c-detail-item>
				<c-detail-item label="监理单位">
					{{detailInfo.jldw_name}}
				</c-detail-item>
				<c-detail-item label="审批材料">
					<c-file :src="detailInfo.file_path"></c-file>
				</c-detail-item>
				<c-detail-item label="签署状态">
					<span v-if="detailInfo.yz_qs_file_path" style="color: #00B575;">已签署</span>
					<span v-else style="color: #1492FF;">待签署</span>
				</c-detail-item>
				<c-detail-item v-if="detailInfo.jl_qs_file_path || detailInfo.yz_qs_file_path" label="签署后审批材料" full>
					<c-file :src="detailInfo.curr_file_path"></c-file>
				</c-detail-item>
			</c-detail>
			
			<c-detail title="审批流程">
				<c-detail-item label="审批记录" full>
					<timeline :data="timeline"></timeline>
				</c-detail-item>
			</c-detail>
			
			<div v-if="approveButton || signButton" slot="button" class="flex-center">
				<c-button color="green" size="m" width="300" @click="$refs.sms.open(approveButton ? userInfo.fgld_phone : userInfo.seal_phone)">{{approveButton ? '审批通过' : '签署'}}</c-button>
				<c-button color="red" size="m" @click="$refs.approve.reject()">驳回</c-button>
			</div>
		</module>
		
		<approve ref="approve" @resolve="approveHandle"></approve>
		
		<smscode ref="sms" :business="id" :scene="approveButton ? 2 : 22" :buttonName="approveButton ? '确认通过' : '确认并签署'" @submit="approveHandle(arguments[0], '', false, arguments[1])"></smscode>
	</page>
</template>

<script>
	import flowNode from '@/components/module/flow_node.vue'
	import timeline from '@/components/module/timeline.vue'
	import approve from '@/components/module/approve.vue'
	import smscode from '@/components/module/smscode.vue'
	import {mapState} from 'vuex'
	
	export default {
		components: {
			flowNode,
			timeline,
			approve,
			smscode
		},
		
		props: {
			id: String
		},
		
		data() {
			return {
				nodeList: [{
					name: '提交申请'
				}, {
					name: '(监理方)签署'
				}, {
					name: '(业主方)审批'
				}, {
					name: '(业主方)签署'
				}, {
					name: '办理完结'
				}],
				detailInfo: {},
				timeline: []
			}
		},
		
		computed: {
			...mapState(['userInfo']),
			
			currentNode() {
				return {
					1: 2,
					2: 3,
					21: 4,
					3: 5
				}[this.detailInfo.state] || 0;
			},
			
			rejectNode() {
				return this.detailInfo.state == 4 ? {
					8: 2,
					41: 3,
					42: 4
				}[this.detailInfo.reject_user_type] : 0;
			},
			
			approveButton() {
				return this.detailInfo.state == 2;
			},
			
			signButton() {
				return this.detailInfo.state == 21;
			}
		},
		
		watch: {
			id() {
				this.updateDetail();
			}
		},
		
		mounted() {
			this.updateDetail();
			this.$store.dispatch('updateUserInfo');
		},
		
		methods: {
			updateDetail() {
				this.request({
					url: '/project/aqtz/detail',
					data: {
						tz_id: this.id
					},
					success: data => {
						this.detailInfo = data.tzInfo;
						this.timeline = Array.from(data.logList, item => {
							return {
								date: item.create_date,
								content: `[${item.create_user_name}] ${item.remark}`
							}
						});
					}
				});
			},
			
			approveHandle(code, remark, stop, next) {
				stop && stop();
				
				this.request({
					url: this.approveButton ? '/project/aqtz/updateStateYzLd' : '/project/aqtz/updateStateYzQs',
					data: {
						tz_id: this.id,
						state: code ? 3 : 4,
						yz_remark: remark,
						verify_code: code
					},
					loading: code && this.signButton ? '请稍等，正在进行在线签章操作...' : true,
					success: data => {
						next();
						this.$message({
							text: '审批成功',
							resolve: () => {
								this.updateDetail();
							}
						});
					}
				});
			}
		}
	};
</script>

<style lang="stylus" scoped>
</style>