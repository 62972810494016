import { render, staticRenderFns } from "./ledger_detail_m.vue?vue&type=template&id=084a76fc&scoped=true"
import script from "./ledger_detail_m.vue?vue&type=script&lang=js"
export * from "./ledger_detail_m.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "084a76fc",
  null
  
)

export default component.exports